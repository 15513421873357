const map = {
	photos: {
		id: 'nav_photos',
		label: 'Photos',
	},
	about: {
		id: 'nav_about',
		label: 'About',
	},
	details: {
		id: 'nav_details',
		label: null,
	},
	pricing: {
		id: 'nav_pricing',
		label: 'Pricing',
	},
	reviews: {
		id: 'nav_reviews',
		label: 'Reviews',
	},
	contact: {
		id: 'nav_contact',
		label: 'Contact',
	},
	owner: {
		id: 'nav_owner',
		label: 'Owner',
	},
};

const defaultActive = map.photos;

const shouldRender = (vendor, navId) => {
	switch (navId) {
		case map.pricing.id:
			return ['REC', 'WPH'].indexOf(vendor.categoryCode) > -1;
		case map.owner.id:
			return !!vendor.bio;
		default:
			return true;
	}
};

export default { map, shouldRender, defaultActive };
